import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
import { BaseSearch } from "@/components/@base";
import { reactive, toRef, watch } from "vue";
import _ from "lodash";
const __default__ = { name: 'BaseSearchGroup' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        modelValue: { default: null },
        placeholder: { default: '' },
        options: { default: null },
        addText: { default: '+ Добавить' }
    },
    emits: ["update:modelValue"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const data = reactive({
            values: _.cloneDeep(props.modelValue)?.map((i, idx) => ({ id: idx + 1, name: i })) || [{ id: 1, name: '' }],
            key: 0
        });
        const emits = __emit;
        const modelValue = toRef(props, 'modelValue');
        const update = (value, id) => {
            const item = data.values.find(i => i.id === id);
            if (item)
                item.name = value;
            const val = data.values.filter(i => i.name !== '');
            const modelValue = val.map(i => i.name);
            emits('update:modelValue', val.length === 0 ? null : modelValue);
        };
        const del = (id) => {
            if (data.values?.length === 1)
                data.values = [{ id: 1, name: '' }];
            if (data.values?.length !== 1)
                data.values = data.values.filter(i => i.id !== id);
            const val = data.values.filter(i => i.name !== '');
            const modelValue = val.map(i => i.name);
            emits('update:modelValue', val.length === 0 ? null : modelValue);
            data.key++;
        };
        const add = () => {
            data.values.push({ id: data.values.length + 1, name: '' });
        };
        watch(modelValue, next => {
            data.values = _.cloneDeep(next)?.map((i, idx) => ({ id: idx + 1, name: i })) || [{ id: 1, name: '' }];
        }, { deep: true });
        return (_ctx, _cache) => {
            const _component_base_icon = _resolveComponent("base-icon");
            return (_openBlock(), _createElementBlock("div", {
                class: "base-search-group",
                key: data.key
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.values, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                        class: "base-search-group__item",
                        key: item.id
                    }, [
                        _createVNode(_unref(BaseSearch), {
                            modelValue: item.name,
                            "onUpdate:modelValue": ($event) => (update($event, item.id)),
                            placeholder: props.placeholder,
                            options: props.options
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "options"]),
                        _createVNode(_component_base_icon, {
                            class: "base-search-group__delete",
                            name: "delete",
                            onClick: ($event) => (del(item.id))
                        }, null, 8, ["onClick"])
                    ]));
                }), 128)),
                (data.values[data.values.length - 1].name !== '')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "base-search-group__add",
                        onClick: add
                    }, _toDisplayString(props.addText), 1))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
