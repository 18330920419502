import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { reactive } from 'vue';
import ScriptDinamicSelectWrapper from '@/components/scripts/select/ScriptDinamicSelectWrapper.vue';
import { BaseSelect } from '@/components/@base/index';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ScriptDinamicSelect',
    props: {
        modelValue: { default: null },
        options: { default: () => ({}) },
        bindElementId: { default: '' },
        elementId: { default: '' }
    },
    setup(__props) {
        const props = __props;
        const data = reactive({
            value: props.modelValue,
        });
        const reset = () => {
            data.value = null;
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(ScriptDinamicSelectWrapper, {
                class: "script-dinamic-select",
                options: props.options,
                "bind-element-id": props.bindElementId,
                "element-id": props.elementId,
                onResetOptions: reset
            }, {
                body: _withCtx(({ options }) => [
                    _createVNode(_unref(BaseSelect), _mergeProps({
                        modelValue: data.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((data.value) = $event))
                    }, _ctx.$attrs, { options: options }), null, 16, ["modelValue", "options"])
                ]),
                _: 1
            }, 8, ["options", "bind-element-id", "element-id"]));
        };
    }
});
