import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "script-dinamic-select-wrapper" };
import { computed, watch } from 'vue';
import { useScriptStore } from "@/store/script";
import { storeToRefs } from "pinia";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ScriptDinamicSelectWrapper',
    props: {
        options: { default: () => ({}) },
        bindElementId: { default: '' },
        elementId: { default: '' }
    },
    emits: ["resetOptions"],
    setup(__props, { emit: __emit }) {
        const { elements } = storeToRefs(useScriptStore());
        const props = __props;
        const emits = __emit;
        const dinamicOptions = computed(() => {
            const element = elements?.value?.find((element) => element.id === props.bindElementId);
            let options = [];
            if (element) {
                const id = element.value;
                if (id) {
                    options = props.options[id];
                }
            }
            return options;
        });
        watch(dinamicOptions, () => {
            const element = elements?.value?.find((element) => element.id === props.elementId);
            if (element) {
                element.value = null;
                emits('resetOptions');
            }
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "body", { options: dinamicOptions.value })
            ]));
        };
    }
});
