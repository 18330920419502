import { useNotification } from "@kyvg/vue3-notification";
// https://www.npmjs.com/package/@kyvg/vue3-notification
const { notify } = useNotification();
export var TYPES;
(function (TYPES) {
    TYPES["error"] = "error";
    TYPES["info"] = "info";
    TYPES["confirm"] = "confirm";
})(TYPES || (TYPES = {}));
const newNotify = notify;
export default newNotify;
