export var INPUT_MODE;
(function (INPUT_MODE) {
    INPUT_MODE["default"] = "default";
    INPUT_MODE["nums"] = "nums";
    INPUT_MODE["phone"] = "phone";
    INPUT_MODE["date"] = "date";
    INPUT_MODE["time"] = "time";
    INPUT_MODE["datetime"] = "datetime";
    INPUT_MODE["dates"] = "dates";
    INPUT_MODE["float"] = "float";
    INPUT_MODE["email"] = "mail";
})(INPUT_MODE || (INPUT_MODE = {}));
export var SELECT_MODE;
(function (SELECT_MODE) {
    SELECT_MODE["default"] = "default";
    SELECT_MODE["scroll"] = "scroll";
})(SELECT_MODE || (SELECT_MODE = {}));
export var CHECKBOX_TYPES;
(function (CHECKBOX_TYPES) {
    CHECKBOX_TYPES["default"] = "default";
    CHECKBOX_TYPES["switch"] = "switch";
})(CHECKBOX_TYPES || (CHECKBOX_TYPES = {}));
export var RADIO_TYPES;
(function (RADIO_TYPES) {
    RADIO_TYPES["default"] = "default";
    RADIO_TYPES["button"] = "button";
    RADIO_TYPES["transparent"] = "transparent";
})(RADIO_TYPES || (RADIO_TYPES = {}));
export var BUTTON_TYPES;
(function (BUTTON_TYPES) {
    BUTTON_TYPES["default"] = "default";
    BUTTON_TYPES["transparent"] = "transparent";
    BUTTON_TYPES["trgray"] = "trgray";
    BUTTON_TYPES["white"] = "white";
    BUTTON_TYPES["constructor"] = "constructor";
})(BUTTON_TYPES || (BUTTON_TYPES = {}));
export var SEARCH_PROPS_MODE;
(function (SEARCH_PROPS_MODE) {
    SEARCH_PROPS_MODE["default"] = "default";
    SEARCH_PROPS_MODE["catalog"] = "catalog";
})(SEARCH_PROPS_MODE || (SEARCH_PROPS_MODE = {}));
export var DATEPICKER_MODE;
(function (DATEPICKER_MODE) {
    DATEPICKER_MODE["date"] = "date";
    DATEPICKER_MODE["time"] = "time";
    DATEPICKER_MODE["datetime"] = "datetime";
    DATEPICKER_MODE["dates"] = "dates";
})(DATEPICKER_MODE || (DATEPICKER_MODE = {}));
export var COMMENT_MODE;
(function (COMMENT_MODE) {
    COMMENT_MODE["default"] = "default";
    COMMENT_MODE["warning"] = "warning";
    COMMENT_MODE["info"] = "info";
})(COMMENT_MODE || (COMMENT_MODE = {}));
