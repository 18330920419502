import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed, toRefs } from 'vue';
import InlineSvg from 'vue-inline-svg';
import { THEME } from "@/components/@types";
const __default__ = { name: 'BaseIcon' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        name: { default: '' },
        width: { default: null },
        height: { default: null },
        size: { default: 24 },
        cursor: { default: 'pointer' },
        hover: { type: Boolean, default: false },
        theme: { default: THEME.light }
    },
    setup(__props) {
        _useCssVars(_ctx => ({
            "0ebb6d10": (props.cursor)
        }));
        const props = __props;
        const { name, width, height, size, cursor } = toRefs(props);
        const srcIcon = computed(() => {
            return `/svg/${name.value}.svg`;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(InlineSvg), _mergeProps({
                class: ["base-icon", [props.hover && 'hover', props.theme]],
                src: srcIcon.value,
                width: _unref(width) ?? _unref(size),
                height: _unref(height) ?? _unref(size)
            }, _ctx.$attrs), null, 16, ["class", "src", "width", "height"]));
        };
    }
});
