import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const __default__ = { name: 'BaseAnchor' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        anchor: { default: '' },
        label: { default: '' }
    },
    emits: ["scrollTo"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emits = __emit;
        const scrollTo = (id) => {
            emits('scrollTo', id);
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("span", {
                class: "base-anchor",
                onClick: _cache[0] || (_cache[0] = ($event) => (scrollTo(props.anchor)))
            }, _toDisplayString(props.label), 1));
        };
    }
});
