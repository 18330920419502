import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "base-comment__data" };
const _hoisted_2 = { class: "base-comment__text" };
import { COMMENT_MODE } from "@/components/@base/@types";
const __default__ = { name: 'BaseComment' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        text: { default: '' },
        mode: { default: COMMENT_MODE.default }
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            const _component_base_icon = _resolveComponent("base-icon");
            return (props.text)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["base-comment", [props.mode]])
                }, [
                    _createVNode(_component_base_icon, {
                        class: "base-comment__icon",
                        name: "comment",
                        size: "16"
                    }),
                    _createElementVNode("p", _hoisted_1, [
                        _createElementVNode("span", _hoisted_2, _toDisplayString(props.text), 1),
                        _createVNode(_component_base_icon, {
                            class: "base-comment__triangle",
                            name: "triangle",
                            width: "5",
                            height: "6"
                        })
                    ])
                ], 2))
                : _createCommentVNode("", true);
        };
    }
});
