export var THEME;
(function (THEME) {
    THEME["light"] = "light";
    THEME["dark"] = "dark";
})(THEME || (THEME = {}));
export var DIRECTION;
(function (DIRECTION) {
    DIRECTION["horizontal"] = "horizontal";
    DIRECTION["vertical"] = "vertical";
})(DIRECTION || (DIRECTION = {}));
export var MENU_MODE;
(function (MENU_MODE) {
    MENU_MODE["default"] = "default";
    MENU_MODE["constructor"] = "constructor";
    MENU_MODE["switch"] = "switch";
    MENU_MODE["admin"] = "admin";
})(MENU_MODE || (MENU_MODE = {}));
export var SECTION_TYPES;
(function (SECTION_TYPES) {
    SECTION_TYPES["sections"] = "sections";
    SECTION_TYPES["objections"] = "objections";
})(SECTION_TYPES || (SECTION_TYPES = {}));
