import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, isRef as _isRef, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "script-consulting-oktell-radio-group" };
import { reactive } from 'vue';
import { BaseRadioGroup } from '@/components/@base/index';
import { RADIO_TYPES } from "@/components/@base/@types";
import { THEME } from "@/components/@types";
import { useOktell } from '@/hooks/useOktell';
import { useLoader } from '@/hooks/useLoader';
const __default__ = { name: 'ScriptConsultingOktellRadioGroup' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        groupId: { default: '' },
        modelValue: { default: null },
        theme: { default: THEME.light },
        type: { default: RADIO_TYPES.default },
        options: { default: () => ([]) },
        direction: {},
        func: {}
    },
    setup(__props) {
        const { dispatchWithoutLoading } = useLoader();
        const props = __props;
        const data = reactive({
            options: [
                ...(props.options || []),
                {
                    id: 'CONFIRM',
                    name: 'Подтвердить'
                },
                {
                    id: 'BACK',
                    name: 'Вернуться'
                }
            ]
        });
        const { currentValue, optionElement, backCall, switchToCallAndHoldClient, confirmCall } = useOktell(props.modelValue, data.options);
        const onHandlerCall = async () => {
            if (optionElement.value?.phone) {
                await dispatchWithoutLoading(() => switchToCallAndHoldClient(optionElement.value?.phone), 'Ошибка перевода звонка!');
                return;
            }
            if (currentValue.value === 'BACK') {
                await dispatchWithoutLoading(() => backCall(), 'Ошибка возврата к клиенту!');
                return;
            }
            if (currentValue.value === 'CONFIRM') {
                await dispatchWithoutLoading(() => confirmCall(), 'Ошибка подтверждения перевода!');
                return;
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_unref(BaseRadioGroup), _mergeProps({
                    modelValue: _unref(currentValue),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_isRef(currentValue) ? (currentValue).value = $event : null)),
                    options: data.options,
                    groupId: props.groupId,
                    theme: props.theme,
                    type: props.type
                }, _ctx.$attrs, { onSelected: onHandlerCall }), null, 16, ["modelValue", "options", "groupId", "theme", "type"])
            ]));
        };
    }
});
