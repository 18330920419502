import { createVNode, render } from 'vue';
import ScriptValue from "@/components/scripts/ScriptValue.vue";
export default {
    name: 'script-value',
    mounted(el, binding) {
        const elems = binding.value();
        setAttributeElements(el, '[href]', elems);
        setAttributeElements(el, '[src]', elems);
        setValueElements(el, elems);
    },
};
function setAttributeElements(el, selector, elems) {
    const elements = el.querySelectorAll(selector);
    if (!elements.length)
        return;
    elements.forEach((element) => {
        Array.from(element.attributes).forEach((attr) => {
            const newValue = replaceValue(attr.value, elems);
            element.setAttribute(attr.name, newValue);
        });
    });
}
function setValueElements(el, elems) {
    const elements = el.querySelectorAll('value');
    if (!elements.length)
        return;
    elements.forEach(item => {
        const span = document.createElement('span');
        span.className = 'script__value';
        const vnode = createVNodeBasedOnAttributes(item, elems);
        if (vnode) {
            item.replaceWith(span);
            render(vnode, span);
        }
    });
}
function createVNodeBasedOnAttributes(item, elems) {
    const attributes = ['user', 'client', 'id'];
    for (const attr of attributes) {
        if (item.hasAttribute(attr)) {
            if (attr === 'id') {
                const element = elems.find(e => e.id === item.id);
                if (element)
                    return createVNode(ScriptValue, { element });
            }
            else {
                return createVNode(ScriptValue, { element: { value: `<${attr}>` } });
            }
        }
    }
}
function replaceValue(originalValue, elems) {
    const entityId = localStorage.getItem('crm-entity-id');
    if (entityId) {
        originalValue = originalValue.replace('entityId', entityId);
    }
    return originalValue.replace(/<value id='([^']+)'(?: [^>]+)?><\/value>/g, (match, id) => {
        const element = elems.find(e => e.id === id);
        return element && element.value ? String(element.value) : match;
    });
}
