import { ref } from 'vue';
import { dispatchWithLoading } from '@/libs/middlewares';
export function useLoader() {
    const isLoading = ref(false);
    const isLoadingButton = ref(false);
    const dispatch = dispatchWithLoading(isLoading);
    const dispatchWithButton = dispatchWithLoading(isLoadingButton);
    const dispatchWithoutLoading = dispatchWithLoading();
    return {
        isLoading,
        dispatch,
        dispatchWithoutLoading,
        dispatchWithButton,
        isLoadingButton
    };
}
