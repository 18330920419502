export function useFormattedScripts() {
    const processValueToJSON = (value) => {
        if (typeof value === 'string' || typeof value === 'number' || value === null || typeof value === 'boolean') {
            return value;
        }
        else {
            return JSON.stringify(value);
        }
    };
    const processValueFromJSON = (value) => {
        if (value === null || typeof value === 'number' || typeof value === 'boolean') {
            return value;
        }
        else if (typeof value === 'string') {
            if (isJSONParsable(value)) {
                try {
                    return JSON.parse(value);
                }
                catch (error) {
                    return value;
                }
            }
            else {
                return value;
            }
        }
        else {
            return value;
        }
    };
    const isJSONParsable = (str) => {
        if (typeof str !== 'string')
            return false;
        // Проверка на строки, которые могут быть только числом или булевым значением
        if (/^\d+$/.test(str) || str === 'true' || str === 'false')
            return false;
        // Проверка на корректный JSON формат
        try {
            const parsed = JSON.parse(str);
            return typeof parsed === 'object' && parsed !== null;
        }
        catch (error) {
            return false;
        }
    };
    const elementsValueFormatToJSON = (elements) => {
        return elements.map((element) => ({
            ...element,
            value: processValueToJSON(element.value)
        }));
    };
    const elementsValueFormatFromJSON = (elements) => {
        return elements.map((element) => ({
            ...element,
            value: processValueFromJSON(element.value)
        }));
    };
    return {
        elementsValueFormatToJSON,
        elementsValueFormatFromJSON,
        processValueFromJSON
    };
}
