import _ from "lodash";
const zeroLength = 2;
const weekdays = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];
export function useFormattedValueElement() {
    const getValueElement = (element) => {
        let value = null;
        switch (element.type) {
            case 8:
            case 9:
            case 11:
            case 12:
            case 13:
            case 14:
                if (element?.props?.options && Array.isArray(element?.props?.options) && element.value) {
                    return element.props.options.find(i => i.id === element?.value)?.name || '';
                }
                else
                    return;
            case 10:
            case 15:
            case 16:
                if (element?.props?.options && Array.isArray(element?.props?.options) && element.value) {
                    return element.props.options.filter(i => element?.value?.includes(i.id)).map(i => i.name).join(', ');
                }
                else
                    return;
            case 24:
            case 25:
                if (Array.isArray(element?.props?.options) && element.value) {
                    const value = _.cloneDeep(element.value);
                    return value.map((it) => {
                        const id = Array.isArray(element?.props?.options) && element?.props?.options?.find((i) => i.id === it.id)?.name;
                        if (id)
                            it.id = id;
                        return it;
                    });
                }
                else
                    return;
            case 17:
                if (element.value)
                    return new Date(element.value).toLocaleDateString();
                else
                    return;
            case 18:
                if (element.value) {
                    const date = new Date(element.value).toLocaleDateString();
                    const time = new Date(element.value).toLocaleTimeString();
                    const weekday = new Date(element.value).getDay();
                    return `${date} (${weekdays[weekday]}), ${time.split(':')[0]}:${time.split(':')[1]}`;
                }
                else
                    return;
            case 19:
                if (element.value) {
                    return element.value.filter(i => i).map(i => new Date(i).toLocaleDateString()).join(' - ');
                }
                else
                    return;
            case 20:
                if (element.value) {
                    const timeValue = element.value;
                    const hours = timeValue.hours.toString().padStart(zeroLength, '0');
                    const minutes = timeValue.minutes.toString().padStart(zeroLength, '0');
                    return `${hours}:${minutes}`;
                }
                else
                    return;
            default:
                try {
                    value = JSON.parse(element.value);
                }
                catch (error) {
                    value = element.value;
                }
                if (Array.isArray(value)) {
                    return Array.isArray(element?.props?.options)
                        ? element?.props?.options.filter((option) => value.includes(option.id))
                            .map((option) => option.name).join(', ') : value.join(', ');
                }
                else {
                    return value;
                }
        }
    };
    return {
        getValueElement
    };
}
