import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from "@/router";
import "@/styles/app.scss";
import * as baseComponents from '@/components/@base/index';
import * as directives from '@/directives/index';
import InlineSvg from 'vue-inline-svg';
import Notifications from '@kyvg/vue3-notification';
export const app = createApp(App);
// app.config.warnHandler = function (msg, vm, trace) {
// 	return null;
// }
// directives
Object.values(directives).forEach((component) => {
    app.directive(component.name, component);
});
import { vMaska } from 'maska';
app.directive('maska', vMaska);
// baseComponents
Object.values(baseComponents).forEach((component) => {
    app.component(component.name, component);
});
// inline-svg
app.component('inline-svg', InlineSvg);
// Datepicker
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
app.component('Datepicker', Datepicker);
// Swiper
import SwiperCore, { Pagination, Navigation, Scrollbar, Mousewheel, Autoplay, Thumbs, Zoom } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
SwiperCore.use([Pagination, Navigation, Scrollbar, Mousewheel, Autoplay, Thumbs, Zoom]);
app.component('swiper', Swiper);
app.component('swiper-slide', SwiperSlide);
// Scrollbar
import * as SmoothScrollbar from "vue3-smooth-scrollbar";
app.component('scrollbar', SmoothScrollbar);
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
app.use(PerfectScrollbar, { options: { wheelPropagation: false } });
// Echarts
import VueEcharts from "vue-echarts";
app.component('v-chart', VueEcharts);
// click-outside
import vClickOutside from "click-outside-vue3";
app.use(vClickOutside);
import { useAppStore } from "@/store/app";
import { useScriptStore } from "@/store/script";
const pinia = createPinia();
app.use(Notifications);
app.use(router);
app.use(pinia);
app.mount('#app');
export const appStore = useAppStore();
export const scriptStore = useScriptStore();
// Плагины:
// https://lodash.com/docs/
// https://www.npmjs.com/package/@kyvg/vue3-notification
// https://swiperjs.com/vue
// https://vue3datepicker.com/
// https://github.com/eladcandroid/v-idle-3
// https://echarts.apache.org/en/index.html
