import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["href", "download"];
const _hoisted_2 = { key: 0 };
import { THEME } from "@/components/@types";
const __default__ = { name: 'BaseLink' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        label: { default: '' },
        href: { default: null },
        download: { type: Boolean, default: false },
        theme: { default: THEME.light }
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("a", {
                href: props.href,
                class: _normalizeClass(["base-link", [props.theme]]),
                download: props.download || null
            }, [
                _renderSlot(_ctx.$slots, "default"),
                (_ctx.label)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(props.label), 1))
                    : _createCommentVNode("", true)
            ], 10, _hoisted_1));
        };
    }
});
