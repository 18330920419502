import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "base-pagination" };
const _hoisted_2 = { class: "base-pagination__total" };
const _hoisted_3 = { class: "base-pagination__total-text" };
const _hoisted_4 = { class: "base-pagination__total-count" };
const _hoisted_5 = { class: "base-pagination__container" };
const _hoisted_6 = { class: "base-pagination__actions" };
import { toRefs, ref, computed } from 'vue';
const __default__ = { name: 'BasePagination' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        totalText: { default: 'Всего' },
        interval: { default: 20 },
        total: { default: 0 },
        siblingCount: { default: 1 },
        pagePagination: { default: 1 }
    },
    emits: ["update:pagePagination", "change-page"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const { totalText, total, interval, pagePagination, siblingCount } = toRefs(props);
        const emits = __emit;
        const DOTS = '...';
        const currentPage = ref(pagePagination.value);
        const totalPages = computed(() => {
            return Math.ceil(total.value / interval.value);
        });
        const offset = computed(() => {
            return (currentPage.value - 1) * interval.value;
        });
        const pagination = computed(() => {
            return totalPages.value ? generatePagination(currentPage.value) : [];
        });
        const isPageActive = (page) => {
            return currentPage.value === page;
        };
        const prevPage = () => {
            currentPage.value--;
            changePageNumber(currentPage.value);
        };
        const nextPage = () => {
            currentPage.value++;
            changePageNumber(currentPage.value);
        };
        const changePageNumber = (pageNumber) => {
            currentPage.value = pageNumber;
            const currentOffset = offset.value;
            const limit = interval.value;
            emits('change-page', { offset: currentOffset, limit });
            emits('update:pagePagination', currentPage.value);
        };
        const range = (start, end) => {
            const length = end - start + 1;
            return Array.from({ length }, (_, index) => index + start);
        };
        const generatePagination = (currentPage) => {
            // Номера страниц которые мы хотим показать
            const totalPageNumbers = siblingCount.value + 4;
            // Если количество страниц меньше, чем номера страниц, которые мы хотим показать то возвращаем диапазон [1..totalPages]
            if (totalPageNumbers >= totalPages.value) {
                return range(1, totalPages.value);
            }
            // Рассчитывает левый и правый индекс родственного элемента и, что они находятся в пределах диапазона от 1 и общего числа страниц.
            const leftSiblingIndex = Math.max(currentPage - siblingCount.value, 1);
            const rightSiblingIndex = Math.min(currentPage + siblingCount.value, totalPages.value);
            // Не показываем точки, если после/до счетчика левой/правой страницы осталась только одна позиция
            const shouldShowLeftDots = leftSiblingIndex > 3;
            const shouldShowRightDots = rightSiblingIndex < totalPages.value - 2;
            const firstPageIndex = 1;
            const lastPageIndex = totalPages.value;
            // Показываем точки справа
            if (!shouldShowLeftDots && shouldShowRightDots) {
                const leftItemCount = 3 + 2 * siblingCount.value;
                const leftRange = range(1, leftItemCount);
                return [...leftRange, DOTS, totalPages.value];
            }
            // Показываем точки слева
            if (shouldShowLeftDots && !shouldShowRightDots) {
                const rightItemCount = 3 + 2 * siblingCount.value;
                const rightRange = range(totalPages.value - rightItemCount + 1, totalPages.value);
                return [firstPageIndex, DOTS, ...rightRange];
            }
            // Показываем точки слева и справа
            if (shouldShowLeftDots && shouldShowRightDots) {
                const middleRange = range(leftSiblingIndex, rightSiblingIndex);
                return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
            }
            return [];
        };
        return (_ctx, _cache) => {
            const _component_base_icon = _resolveComponent("base-icon");
            const _component_base_button = _resolveComponent("base-button");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(totalText)) + ": ", 1),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(total)), 1)
                ]),
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_base_button, {
                            class: "base-pagination__action-button",
                            disabled: currentPage.value === 1,
                            onClick: prevPage
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_base_icon, {
                                    class: "base-pagination__prev-icon",
                                    name: "arrow-l"
                                })
                            ]),
                            _: 1
                        }, 8, ["disabled"]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pagination.value, (page) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: page }, [
                                (page === DOTS)
                                    ? (_openBlock(), _createBlock(_component_base_button, {
                                        key: 0,
                                        class: "base-pagination__action-button",
                                        label: page,
                                        disabled: ""
                                    }, null, 8, ["label"]))
                                    : (_openBlock(), _createBlock(_component_base_button, {
                                        key: 1,
                                        class: _normalizeClass(["base-pagination__action-button", { 'base-pagination__action-button--active': isPageActive(page) }]),
                                        label: page.toString(),
                                        onClick: ($event) => (changePageNumber(page))
                                    }, null, 8, ["class", "label", "onClick"]))
                            ], 64));
                        }), 128)),
                        _createVNode(_component_base_button, {
                            disabled: currentPage.value === totalPages.value,
                            class: "base-pagination__action-button",
                            onClick: nextPage
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_base_icon, {
                                    class: "base-pagination__next-icon",
                                    name: "arrow-r"
                                })
                            ]),
                            _: 1
                        }, 8, ["disabled"])
                    ])
                ])
            ]));
        };
    }
});
