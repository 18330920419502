import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "base-textarea__title"
};
const _hoisted_2 = ["value", "placeholder"];
import { THEME } from "@/components/@types";
import { onMounted, reactive, ref } from "vue";
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
const __default__ = { name: 'BaseTextarea' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        modelValue: { default: '' },
        placeholder: { default: '' },
        theme: { default: THEME.light },
        required: { type: Boolean, default: false },
        width: { default: '100%' },
        height: { default: '90px' }
    },
    emits: ["update:modelValue", "focus", "confirmEntry", "element"],
    setup(__props, { emit: __emit }) {
        _useCssVars(_ctx => ({
            "233fb553": (props.width),
            "2ae7fb3a": (props.height)
        }));
        const props = __props;
        const textarea = ref(undefined);
        const scroll = ref(undefined);
        const data = reactive({
            error: false,
            isFirstInput: true,
        });
        const emits = __emit;
        const update = (e) => {
            let value = e.target?.value.trim();
            if (data.isFirstInput && value === '') {
                data.isFirstInput = false;
            }
            else {
                data.error = props.required && value === '';
            }
            data.isFirstInput = false;
            e.target.style.height = (e.target.scrollHeight) + "px";
            emits('update:modelValue', value);
        };
        const jumpNewLine = (e) => {
            const getCaretCoordinates = require('textarea-caret');
            const end = e.target.selectionEnd;
            const caret = getCaretCoordinates(e.target, end);
            // scroll.value.update();
            // nextTick(() => scroll.value.scrollTo(0, caret.top + caret.height));
            scroll.value.$el.scrollTop = caret.top + caret.height;
        };
        onMounted(() => {
            emits('element', textarea.value);
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("label", {
                class: _normalizeClass(["base-textarea", [props.theme, data.error && 'error', !props.modelValue && 'empty']])
            }, [
                (_ctx.$slots.title)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                        _renderSlot(_ctx.$slots, "title")
                    ]))
                    : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "default"),
                _createVNode(_unref(PerfectScrollbar), {
                    class: "base-textarea__wrapper base-textarea__scroll",
                    ref_key: "scroll",
                    ref: scroll
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("textarea", {
                            value: props.modelValue,
                            ref_key: "textarea",
                            ref: textarea,
                            class: "base-textarea__input",
                            placeholder: props.placeholder,
                            onInput: update,
                            onKeydown: _withKeys(jumpNewLine, ["enter"])
                        }, null, 40, _hoisted_2)
                    ]),
                    _: 1
                }, 512)
            ], 2));
        };
    }
});
