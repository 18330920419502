import { useScriptStore } from "@/store/script";
import { storeToRefs } from "pinia";
export default function useUpdateHistoryValue(elementId, value) {
    const { history } = storeToRefs(useScriptStore());
    const [messageCounter, ...historyItems] = history.value;
    historyItems.forEach(historyItem => {
        if (historyItem.elementId === elementId) {
            historyItem.currentValue = value;
            historyItem.timeChangedValue = Math.floor(Date.now() / 1000);
        }
    });
}
