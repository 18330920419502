import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "base-table" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = {
    key: 0,
    class: "base-table__pagination"
};
const _hoisted_5 = {
    key: 1,
    class: "base-table__loader-container"
};
const __default__ = { name: 'BaseTable' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        columns: { default: null },
        rows: { default: null },
        loading: { type: Boolean, default: false },
        paginated: { type: Boolean, default: false },
        totalText: { default: 'Всего' },
        interval: { default: 20 },
        total: { default: 0 },
        pagePagination: { default: 1 }
    },
    emits: ["reachEnd", "change-page", "update:pagePagination"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emits = __emit;
        const changePage = ({ offset, limit }) => {
            emits('change-page', { offset, limit });
        };
        const updatePagePagination = (page) => {
            emits('update:pagePagination', page);
        };
        return (_ctx, _cache) => {
            const _component_perfect_scrollbar = _resolveComponent("perfect-scrollbar");
            const _component_base_pagination = _resolveComponent("base-pagination");
            const _component_base_loader = _resolveComponent("base-loader");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_perfect_scrollbar, {
                    class: _normalizeClass(["base-table__scroll", { 'base-table__scroll-paginated': props.paginated }]),
                    ref: "scroll",
                    options: { wheelPropagation: true, suppressScrollX: true },
                    onPsYReachEnd: _cache[0] || (_cache[0] = () => emits('reachEnd'))
                }, {
                    default: _withCtx(() => [
                        (props.columns && props.columns.length > 0)
                            ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
                                (props.rows && props.rows.length > 0)
                                    ? (_openBlock(), _createElementBlock("thead", _hoisted_3, [
                                        _createElementVNode("tr", null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.columns, (col) => {
                                                return (_openBlock(), _createElementBlock("th", {
                                                    key: col.id,
                                                    style: _normalizeStyle(`width: ${col.width};`),
                                                    class: _normalizeClass(`${col.id}`)
                                                }, _toDisplayString(col.label), 7));
                                            }), 128))
                                        ])
                                    ]))
                                    : _createCommentVNode("", true),
                                _createElementVNode("tbody", null, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.rows, (row, index) => {
                                        return (_openBlock(), _createElementBlock("tr", {
                                            key: row.id
                                        }, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.columns, (col) => {
                                                return (_openBlock(), _createElementBlock("td", {
                                                    key: col.id,
                                                    class: _normalizeClass(`${col.id}`)
                                                }, [
                                                    (_ctx.$slots && _ctx.$slots[col.id])
                                                        ? _renderSlot(_ctx.$slots, col.id, {
                                                            key: 0,
                                                            col: col,
                                                            row: row,
                                                            index: index
                                                        })
                                                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                            _createTextVNode(_toDisplayString(row[col.id]), 1)
                                                        ], 64))
                                                ], 2));
                                            }), 128))
                                        ]));
                                    }), 128))
                                ])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 3
                }, 8, ["class"]),
                (_ctx.paginated)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _withDirectives(_createVNode(_component_base_pagination, {
                            total: props.total,
                            "total-text": props.totalText,
                            interval: props.interval,
                            "page-pagination": props.pagePagination,
                            onChangePage: changePage,
                            "onUpdate:pagePagination": updatePagePagination
                        }, null, 8, ["total", "total-text", "interval", "page-pagination"]), [
                            [_vShow, props.rows && props.rows.length > 0]
                        ])
                    ]))
                    : _createCommentVNode("", true),
                (props.loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_base_loader, { class: "base-table__loader" })
                    ]))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
