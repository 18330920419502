import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "script-value__text"
};
import { computed } from "vue";
import { useUserStore } from "@/store/user";
import { useClientStore } from "@/store/client";
import { storeToRefs } from "pinia";
import { useFormattedValueElement } from '@/hooks/useFormattedValueElement';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ScriptValue',
    props: {
        element: { default: null }
    },
    setup(__props) {
        const { getValueElement } = useFormattedValueElement();
        const { user } = storeToRefs(useUserStore());
        const { client } = storeToRefs(useClientStore());
        const props = __props;
        const value = computed(() => {
            if (!props.element)
                return;
            if (props.element.value === '<client>' && client.value?.name)
                return client.value.name.value;
            if (props.element.value === '<user>' && user.value?.userData?.name)
                return user.value?.userData.name;
            if (props.element.value !== '<client>' && props.element.value !== '<user>') {
                return getValueElement(props.element);
            }
            return null;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                (value.value && (typeof value.value === 'string' || typeof value.value === 'number'))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(value.value), 1))
                    : _createCommentVNode("", true),
                (value.value && (typeof value.value === 'object'))
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(value.value, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: item.id,
                            class: "script-value__block"
                        }, [
                            _createElementVNode("span", null, _toDisplayString(item.id || item.hours), 1),
                            _createElementVNode("span", null, _toDisplayString(item.value || item.minutes), 1)
                        ]));
                    }), 128))
                    : _createCommentVNode("", true)
            ], 64));
        };
    }
});
