import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue";
import { RADIO_TYPES } from "@/components/@base/@types";
import { BaseRadio } from "@/components/@base";
import { THEME, DIRECTION } from "@/components/@types";
import { reactive } from "vue";
const __default__ = { name: 'BaseRadioGroup' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        groupId: { default: '' },
        modelValue: { default: null },
        theme: { default: THEME.light },
        type: { default: RADIO_TYPES.default },
        options: { default: () => [] },
        direction: { default: DIRECTION.horizontal },
        func: { type: Function, default: () => { } }
    },
    emits: ["update:modelValue", "scrollTo", "selected"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const data = reactive({
            disabled: []
        });
        const emits = __emit;
        const update = (v) => {
            if (v !== null) {
                emits('update:modelValue', v);
                emits('selected');
            }
        };
        const disable = (v) => {
            if (v && props.options)
                props.options.forEach(i => {
                    data.disabled.push(i.id);
                });
            if (!v && data.disabled.length > 0)
                data.disabled = [];
        };
        const scrollTo = (id) => {
            emits('scrollTo', id);
        };
        return (_ctx, _cache) => {
            return (props.options && props.options?.length > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(['base-radio-group', props.direction])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.options, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                            class: "base-radio-group__item",
                            key: item.id
                        }, [
                            _createVNode(_unref(BaseRadio), {
                                modelValue: props.modelValue === item.id ? props.modelValue : null,
                                groupId: props.groupId,
                                id: item.id,
                                label: item.name,
                                type: props.type,
                                func: props.func,
                                disabled: data.disabled.includes(item.id),
                                anchor: item.anchor,
                                "onUpdate:modelValue": update,
                                onLoading: disable,
                                onScrollTo: scrollTo
                            }, null, 8, ["modelValue", "groupId", "id", "label", "type", "func", "disabled", "anchor"])
                        ]));
                    }), 128))
                ], 2))
                : _createCommentVNode("", true);
        };
    }
});
