import { default as notify, TYPES } from "@/plugins/notify";
/**
 * Глобальный обработчик ошибок. Включает в себя игнорирование обработок конкретных кодов (как правило, такие коды
 * обрабатывается на стороне интерцептора)
 * @param error Объект ошибки из axios
 * @param message Текст ошибки
 */
export const errorResponse = (error, message) => {
    const ignoreCodes = [401];
    if (error.response) {
        if (!ignoreCodes.includes(error.response.status)) {
            notify({
                title: 'Ошибка!',
                text: message,
                type: TYPES.error
            });
        }
    }
    else {
        notify({
            title: 'Ошибка!',
            text: 'Обратитесь к администратору системы!',
            type: TYPES.error
        });
    }
};
/**
 * Глобальный мидлварь для функций в компонентах. С помощью каррирования мы помещаем первый вызов в переменную, указывая
 * реф на состояние загрузки, а второй вызов используем внутри компонентных функций
 * @param loadState Состояние загрузки в компоненте (таблицы, или страницы в целом)
 */
export const dispatchWithLoading = (loadState) => {
    /**
     * Возвращаемая функция для колбэка
     * @param callback Колбэк, внутри которого, как правило, вызывается store.dispatch
     * @param message Текст ошибки
     */
    return async (callback, message) => {
        if (loadState) {
            loadState.value = true;
        }
        try {
            const result = await callback();
            return result;
        }
        catch (e) {
            errorResponse(e, message);
            return Promise.reject();
        }
        finally {
            if (loadState) {
                loadState.value = false;
            }
        }
    };
};
