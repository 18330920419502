import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "node-treeview" };
const _hoisted_2 = { class: "node-treeview__item" };
const _hoisted_3 = { class: "node-treeview__item-row" };
const _hoisted_4 = {
    key: 2,
    class: "node-treeview__name"
};
const _hoisted_5 = { class: "node-treeview__children" };
import { ref, toRefs, computed, watch } from 'vue';
import { THEME } from "@/components/@types";
const __default__ = { name: 'NodeTreeview' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        item: { default: () => ({}) },
        theme: { default: THEME.light },
        selectable: { type: Boolean, default: false },
        openAll: { type: Boolean, default: false },
        single: { type: Boolean, default: false },
        selectedItems: { default: () => ([]) }
    },
    emits: ["toggle", "select"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const { item } = toRefs(props);
        const open = ref(props.openAll);
        const emits = __emit;
        const hasChildren = computed(() => {
            return item.value.children && item.value.children.length > 0;
        });
        const showCheckbox = computed(() => {
            return (props.selectable && !hasChildren.value && props.single) || (props.selectable && !props.single);
        });
        const iconOpen = computed(() => {
            return !open.value ? 'plus' : 'minus';
        });
        const onToggle = () => {
            open.value = !open.value;
        };
        const select = (data) => {
            item.value.checked = data.checked;
            if (hasChildren.value) {
                updateChildrenCheckedState(item.value.children, data.checked);
            }
            emits('select', { item: item.value, parent: null });
        };
        const selectChild = (payload) => {
            if (item.value.children) {
                item.value.checked = item.value.children.every(child => child.checked);
            }
            emits('select', { item: payload.item, parent: item.value });
        };
        const updateChildrenCheckedState = (children, checked) => {
            const stack = [...children];
            while (stack.length > 0) {
                const child = stack.pop();
                child.checked = checked;
                if (child.children && child.children.length > 0) {
                    stack.push(...child.children);
                }
            }
        };
        const isItemIndeterminate = (item) => {
            if (!item.children || item.children.length === 0) {
                return false;
            }
            const stack = [...item.children];
            let hasChecked = false;
            let hasUnchecked = false;
            while (stack.length > 0) {
                const current = stack.pop();
                if (current.checked) {
                    hasChecked = true;
                }
                else {
                    hasUnchecked = true;
                }
                if (hasChecked && hasUnchecked) {
                    return true;
                }
                if (current.children && current.children.length > 0) {
                    stack.push(...current.children);
                }
            }
            return false;
        };
        watch(() => props.selectedItems, () => {
            if (props.single) {
                item.value.checked = props.selectedItems.includes(item.value.id);
            }
        });
        return (_ctx, _cache) => {
            const _component_base_icon = _resolveComponent("base-icon");
            const _component_base_checkbox = _resolveComponent("base-checkbox");
            const _component_node_treeview = _resolveComponent("node-treeview", true);
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        (hasChildren.value)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "node-treeview__open",
                                onClick: onToggle
                            }, [
                                _createVNode(_component_base_icon, {
                                    class: "node-treeview__icon-open",
                                    name: iconOpen.value,
                                    size: "11"
                                }, null, 8, ["name"])
                            ]))
                            : _createCommentVNode("", true),
                        (showCheckbox.value)
                            ? (_openBlock(), _createBlock(_component_base_checkbox, {
                                key: 1,
                                checked: _unref(item).checked,
                                "onUpdate:checked": _cache[0] || (_cache[0] = ($event) => ((_unref(item).checked) = $event)),
                                "is-indeterminate": !props.single ? isItemIndeterminate(_unref(item)) : false,
                                id: _unref(item).id,
                                label: _unref(item).name,
                                theme: props.theme,
                                onUpdate: select
                            }, null, 8, ["checked", "is-indeterminate", "id", "label", "theme"]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(item).name), 1))
                    ])
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(item).children, (child) => {
                        return (_openBlock(), _createBlock(_component_node_treeview, {
                            key: child.id,
                            item: child,
                            theme: props.theme,
                            selectable: props.selectable,
                            "open-all": props.openAll,
                            single: props.single,
                            "selected-items": props.selectedItems,
                            class: "node-treeview__child",
                            onSelect: selectChild
                        }, null, 8, ["item", "theme", "selectable", "open-all", "single", "selected-items"]));
                    }), 128))
                ], 512), [
                    [_vShow, hasChildren.value && open.value]
                ])
            ]));
        };
    }
});
