let observer = null;
export default {
    name: 'resize',
    mounted(el, binding) {
        observer = new ResizeObserver(() => binding.value());
        observer.observe(el);
    },
    unmounted(el) {
        if (observer)
            observer.unobserve(el);
    }
};
