import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "base-treeview" };
const _hoisted_2 = {
    key: 0,
    class: "base-treeview__search"
};
const _hoisted_3 = { class: "base-treeview__selected-names" };
const _hoisted_4 = { class: "base-treeview__lists" };
const _hoisted_5 = {
    key: 0,
    class: "base-treeview__no-search-text"
};
import { ref, watch, computed } from 'vue';
import { THEME } from "@/components/@types";
import NodeTreeview from '@/components/@base/tree/NodeTreeview.vue';
const __default__ = { name: 'BaseTreeview' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        items: { default: () => ([]) },
        modelValue: { default: () => ([]) },
        openAll: { type: Boolean, default: false },
        theme: { default: THEME.light },
        selectable: { type: Boolean, default: false },
        search: { type: Boolean, default: false },
        single: { type: Boolean, default: false }
    },
    emits: ["update:modelValue"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const normalizedItems = ref([]);
        const selectedNames = ref([]);
        const searchQuery = ref('');
        const emits = __emit;
        const selectedNamesDisplay = computed(() => {
            return selectedNames.value.join(', ');
        });
        const getNormalizedItems = (items) => {
            return items.map(item => normalizeItem(item));
        };
        const normalizeItem = (item) => {
            const newItem = {
                ...item,
                checked: props.modelValue.includes(item.id),
                children: item.children ? item.children.map(normalizeItem) : []
            };
            if (props.modelValue.includes(item.id)) {
                selectedNames.value.push(item.name);
            }
            return newItem;
        };
        const updateParentCheckedState = (items) => {
            const stack = [...items];
            while (stack.length > 0) {
                const item = stack.pop();
                if (item.children && item.children.length > 0) {
                    stack.push(...item.children);
                    item.checked = item.children.every(child => child.checked);
                }
            }
        };
        watch(() => props.items, () => {
            normalizedItems.value = getNormalizedItems(props.items);
            if (!props.single) {
                updateParentCheckedState(normalizedItems.value);
            }
        }, { immediate: true });
        const searchedTree = computed(() => {
            return searchTree(normalizedItems.value, searchQuery.value);
        });
        const selectedItemsAndNames = computed(() => {
            const stack = [...searchedTree.value];
            const selectedItems = [];
            const selectedNames = [];
            while (stack.length > 0) {
                const item = stack.pop();
                if (item?.checked) {
                    selectedItems.push(item.id);
                    selectedNames.push(item.name);
                }
                if (item?.children && item?.children.length > 0) {
                    stack.push(...item?.children);
                }
            }
            return {
                items: selectedItems,
                names: selectedNames,
            };
        });
        const selectItem = (payload) => {
            const item = payload.item;
            let newValue = [];
            if (props.single) {
                newValue = [item.id];
                selectedNames.value = [item.name];
            }
            else {
                newValue = selectedItemsAndNames.value.items;
                selectedNames.value = selectedItemsAndNames.value.names;
            }
            emits('update:modelValue', newValue);
        };
        const searchTree = (items, query) => {
            if (!query)
                return items;
            return items.map(item => {
                if (item.children) {
                    const filteredChildren = searchTree(item.children, query);
                    if (filteredChildren.length) {
                        return { ...item, children: filteredChildren };
                    }
                }
                if (item.name.toLowerCase().includes(query.toLowerCase())) {
                    return item;
                }
                return null;
            }).filter((item) => item);
        };
        return (_ctx, _cache) => {
            const _component_base_search = _resolveComponent("base-search");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (props.search)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_base_search, {
                            modelValue: searchQuery.value,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((searchQuery).value = $event)),
                            placeholder: "Поиск..."
                        }, null, 8, ["modelValue"])
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "base-treeview__checked-text" }, "Выбрано: ", -1)),
                    _cache[2] || (_cache[2] = _createTextVNode()),
                    _createElementVNode("span", null, _toDisplayString(selectedNamesDisplay.value), 1)
                ]),
                _createElementVNode("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchedTree.value, (item) => {
                        return (_openBlock(), _createBlock(NodeTreeview, {
                            key: item.id,
                            item: item,
                            theme: props.theme,
                            selectable: props.selectable,
                            "open-all": props.openAll,
                            single: props.single,
                            "selected-items": props.modelValue,
                            onSelect: selectItem
                        }, null, 8, ["item", "theme", "selectable", "open-all", "single", "selected-items"]));
                    }), 128)),
                    (!searchedTree.value.length && props.search)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Ничего не найдено"))
                        : _createCommentVNode("", true)
                ])
            ]));
        };
    }
});
