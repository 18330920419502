import { ref, computed } from 'vue';
import { switchto, disconnectcall, flashcall, callto } from '@/api/oktell';
const TIMEOUT = 500;
export function useOktell(initData = null, options = []) {
    const currentValue = ref(initData);
    const optionElement = computed(() => {
        return options.find((option) => option.id === currentValue.value);
    });
    const callTo = async (phone) => {
        await callto(phone);
    };
    const disconnectCall = async () => {
        await disconnectcall();
    };
    const switchToCall = async (phone) => {
        await switchto(phone);
    };
    const switchToCallAndDisconnect = async (phone) => {
        await switchToCall(phone);
        setTimeout(async () => {
            await disconnectCall();
        }, TIMEOUT);
    };
    const flashCall = async () => {
        await flashcall();
    };
    const switchToCallAndHoldClient = async (phone) => {
        await switchToCall(phone);
    };
    const confirmCall = async () => {
        await disconnectCall();
    };
    const backCall = async () => {
        await flashCall();
        setTimeout(async () => {
            await flashCall();
        }, TIMEOUT);
    };
    return {
        currentValue,
        optionElement,
        switchToCallAndDisconnect,
        flashCall,
        backCall,
        confirmCall,
        switchToCallAndHoldClient,
        callTo,
        disconnectCall
    };
}
