import axios from "axios";
import { useAuthStore } from "@/store/auth";
import { Crm } from '@/api/@types';
const config = {
    baseURL: process.env.VUE_APP_API,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        version: 1
    },
};
const _axios = axios.create(config);
let subscribers = [];
let isRefreshing = false;
function refreshToken() {
    if (!isRefreshing) {
        isRefreshing = true;
        return doRefresh();
    }
    return new Promise(resolve => subscribers.push(resolve));
}
function doRefresh() {
    const authStore = useAuthStore();
    const refreshToken = localStorage.getItem('crm-refresh-token');
    if (refreshToken) {
        return authStore.refreshToken({ refreshToken: refreshToken }).then(newToken => {
            isRefreshing = false;
            subscribers.forEach((resolveSubscription) => resolveSubscription(newToken));
            return newToken;
        })
            .catch(removeAuthorization)
            .finally(() => { subscribers = []; });
    }
}
async function requestValidAccessToken() {
    let token = localStorage.getItem('crm-token');
    const expires = localStorage.getItem('crm-expires');
    if (!token || !expires)
        return null;
    const now = Math.floor(Date.now() * 0.001);
    const isAccessTokenValid = now < Number(expires);
    if (!isAccessTokenValid) {
        await refreshToken();
        token = localStorage.getItem('crm-token');
    }
    return token;
}
_axios.interceptors.request.use(async (config) => {
    let token;
    if (!(config.url === `/auth/${Crm.BITRIX}` || config.url === '/auth/token/refresh')) {
        token = await requestValidAccessToken();
    }
    else {
        token = localStorage.getItem('crm-token');
    }
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
_axios.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const { response: { status, data } } = error;
    if (status === 401 && data.detail === 'Unauthorized') {
        removeAuthorization();
    }
    return Promise.reject(error);
});
function removeAuthorization() {
    window.location.reload();
    localStorage.removeItem('crm-token');
    localStorage.removeItem('crm-refresh-token');
    localStorage.removeItem('crm-user-id');
    localStorage.removeItem('crm-member-id');
    localStorage.removeItem('crm-expires');
}
export default _axios;
