import { defineComponent as _defineComponent } from 'vue';
import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["accept", "multiple"];
const _hoisted_2 = {
    key: 0,
    class: "base-file__content"
};
import { reactive, ref } from "vue";
import { default as notify, TYPES } from "@/plugins/notify";
const __default__ = { name: 'BaseFile' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        extensions: { default: null },
        size: { default: null },
        multiple: { type: Boolean, default: false }
    },
    emits: ["formData", "files"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const data = reactive({
            error: false,
            files: null
        });
        const emits = __emit;
        const file = ref(undefined);
        const clear = () => {
            if (file.value)
                file.value.value = '';
        };
        const form = ref(undefined);
        const getFiles = () => {
            const formData = new FormData(form.value);
            data.files = formData.getAll('files');
            if (!data.files || data.files.length === 0 || data.files.length === 1 && data.files[0].size === 0)
                return;
            data.error = false;
            validateExt();
            validateSize();
            if (!data.error) {
                emits('formData', formData);
                emits('files', data.files);
            }
        };
        // Проверка расширений файлов на соответствие переданных расширений в пропсе
        const validateExt = () => {
            if (props.extensions && props.extensions.length > 0) {
                const extensions = []; // массив расширений загруженных файлов
                data.files?.map((item) => {
                    if (item instanceof File)
                        extensions.push('.' + item.name.slice(item.name.lastIndexOf('.') + 1));
                });
                extensions.forEach(item => {
                    if (!props.extensions?.includes(item)) {
                        data.error = true;
                        notify({
                            title: 'Ошибка загрузки',
                            text: `Разрешенные типы файлов: ${props.extensions?.join(', ')}`,
                            type: TYPES.error
                        });
                    }
                });
            }
        };
        // Проверка суммарного размера файлов на соответствие переданного размера в МБ в пропсе
        const validateSize = () => {
            if (props.size && data.files) {
                const size = props.size * 1024 * 1024; // разрешенный размер в байтах
                const sumSize = data.files.reduce((acc, item) => {
                    acc = acc + item.size;
                    return acc;
                }, 0);
                if (sumSize > size) {
                    data.error = true;
                    notify({
                        title: 'Ошибка загрузки',
                        text: `Размер загружаемых файлов не должен превышать ${props.size} МБ`,
                        type: TYPES.error
                    });
                }
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("form", {
                class: "base-file",
                ref_key: "form",
                ref: form
            }, [
                _createElementVNode("label", null, [
                    _createElementVNode("input", {
                        onInput: getFiles,
                        class: "base-file__input",
                        name: "files",
                        type: "file",
                        accept: props.extensions,
                        multiple: props.multiple,
                        onClick: _withModifiers(clear, ["stop"]),
                        ref_key: "file",
                        ref: file
                    }, null, 40, _hoisted_1),
                    (_ctx.$slots && _ctx.$slots.default)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _renderSlot(_ctx.$slots, "default")
                        ]))
                        : _createCommentVNode("", true)
                ])
            ], 512));
        };
    }
});
