import { defineComponent as _defineComponent } from 'vue';
import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["value", "checked", "disabled", "indeterminate"];
const _hoisted_2 = {
    key: 0,
    class: "base-checkbox__text"
};
import { CHECKBOX_TYPES } from "@/components/@base/@types";
import { THEME } from "@/components/@types";
const __default__ = { name: 'BaseCheckbox' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        id: { default: '' },
        checked: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        label: { default: '' },
        theme: { default: THEME.light },
        anchor: { default: '' },
        type: { default: CHECKBOX_TYPES.default },
        isIndeterminate: { type: Boolean, default: false }
    },
    emits: ["update:checked", "update", "scrollTo"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emits = __emit;
        const update = (e) => {
            if (props.anchor)
                return;
            emits('update:checked', e.target?.checked);
            emits('update', { checked: e.target?.checked, id: props.id });
        };
        const scrollTo = (id) => {
            if (id)
                emits('scrollTo', id);
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("label", {
                class: _normalizeClass(["base-checkbox", [props.disabled && 'disabled', `type-${props.type}`, props.theme, props.isIndeterminate && 'indeterminate']]),
                onClick: _cache[0] || (_cache[0] = ($event) => (scrollTo(props.anchor)))
            }, [
                _createElementVNode("input", _mergeProps({
                    value: props.id,
                    type: "checkbox",
                    class: "base-checkbox__input",
                    checked: props.checked,
                    disabled: props.disabled,
                    indeterminate: props.isIndeterminate,
                    onInput: update
                }, _ctx.$attrs), null, 16, _hoisted_1),
                _renderSlot(_ctx.$slots, "prepend"),
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "base-checkbox__trigger" }, null, -1)),
                (props.label)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(props.label), 1))
                    : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "default")
            ], 2));
        };
    }
});
