import { createWebHistory, createRouter } from "vue-router";
import routes from "@/router/routes";
import { useAuthStore } from "@/store/auth";
const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    let token = localStorage.getItem('crm-token');
    if (to.path !== '/bcrm-login' && !token) {
        const refreshToken = localStorage.getItem('crm-refresh-token');
        if (refreshToken) {
            await authStore.refreshToken({ refreshToken: refreshToken });
            token = localStorage.getItem('crm-token');
        }
        if (!token) {
            try {
                await authStore.redirectToAuthPage();
            }
            catch (error) {
                console.log(error);
            }
            return;
        }
    }
    const defaultTitle = 'Интерактивный скрипт';
    if (!to.meta.title)
        document.title = defaultTitle;
    if (typeof to.meta.title === "string") {
        document.title = to.meta.title;
    }
    if (typeof to.meta.title === "function") {
        document.title = to.meta.title(to);
    }
    next();
});
export default router;
