import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, isRef as _isRef, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { BaseRadioGroup } from '@/components/@base/index';
import { RADIO_TYPES } from "@/components/@base/@types";
import { THEME } from "@/components/@types";
import { useOktell } from '@/hooks/useOktell';
import { useLoader } from '@/hooks/useLoader';
const __default__ = { name: 'ScriptOktellRadioGroup' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        groupId: { default: '' },
        modelValue: { default: null },
        theme: { default: THEME.light },
        type: { default: RADIO_TYPES.default },
        options: { default: () => ([]) },
        direction: {},
        func: {}
    },
    setup(__props) {
        const { dispatchWithoutLoading } = useLoader();
        const props = __props;
        const { currentValue, optionElement, switchToCallAndDisconnect } = useOktell(props.modelValue, props.options);
        const onHandlerCall = async () => {
            if (!optionElement.value?.phone)
                return;
            await dispatchWithoutLoading(() => switchToCallAndDisconnect(optionElement.value?.phone), 'Ошибка перевода звонка!');
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(BaseRadioGroup), _mergeProps({
                modelValue: _unref(currentValue),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_isRef(currentValue) ? (currentValue).value = $event : null)),
                options: props.options,
                groupId: props.groupId,
                theme: props.theme,
                type: props.type
            }, _ctx.$attrs, { onSelected: onHandlerCall }), null, 16, ["modelValue", "options", "groupId", "theme", "type"]));
        };
    }
});
