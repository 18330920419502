import _axios from "@/plugins/axios";
export const getAuthPage = async () => {
    const { data } = await _axios.get('/auth/get_authorization_page');
    return data;
};
export const getToken = async (crmType, payload) => {
    const { data } = await _axios.get(`/auth/${crmType}`, { params: payload });
    return data;
};
export const getRefreshToken = async (refreshToken) => {
    const { data } = await _axios.get('/auth/token/refresh', { params: refreshToken });
    return data;
};
