import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "base-colorpicker" };
const _hoisted_2 = {
    key: 0,
    class: "base-colorpicker__icon"
};
const _hoisted_3 = ["type"];
import { ref } from "vue";
const __default__ = { name: 'BaseColorpicker' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        modelValue: { default: '#000000' }
    },
    emits: ["update:modelValue"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const opened = ref(false);
        const input = ref(null);
        const emits = __emit;
        const togglePicker = () => {
            opened.value = !opened.value;
        };
        const closePicker = () => {
            opened.value = false;
        };
        const update = (e) => {
            emits('update:modelValue', e.target.value);
        };
        return (_ctx, _cache) => {
            const _directive_click_outside = _resolveDirective("click-outside");
            return _withDirectives((_openBlock(), _createElementBlock("label", _hoisted_1, [
                (_ctx.$slots.default)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                        _renderSlot(_ctx.$slots, "default")
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("input", {
                    ref_key: "input",
                    ref: input,
                    class: "base-colorpicker__input",
                    type: opened.value ? 'color' : 'text',
                    onClick: togglePicker,
                    onInput: update
                }, null, 40, _hoisted_3)
            ])), [
                [_directive_click_outside, closePicker]
            ]);
        };
    }
});
