import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["src"];
const __default__ = { name: 'BaseAvatar' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        src: { default: '' },
        width: { default: 44 },
        height: { default: 44 }
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (props.src)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "base-avatar",
                    style: _normalizeStyle(`min-width: ${props.width}px; width: ${props.width}px; height: ${props.height}px`)
                }, [
                    _createElementVNode("img", {
                        src: props.src,
                        alt: ""
                    }, null, 8, _hoisted_1)
                ], 4))
                : _createCommentVNode("", true);
        };
    }
});
