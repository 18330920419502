import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "base-modal__wrapper" };
const _hoisted_2 = { class: "base-modal__window" };
const _hoisted_3 = { class: "base-modal__header" };
const _hoisted_4 = { class: "base-modal__title" };
const _hoisted_5 = {
    key: 1,
    class: "base-modal__actions"
};
import { THEME } from "@/components/@types";
const __default__ = { name: 'BaseModal' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        title: { default: '' },
        theme: { default: THEME.light },
        scrollbar: { type: Boolean, default: false }
    },
    emits: ["close"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emits = __emit;
        const close = () => {
            emits('close');
        };
        return (_ctx, _cache) => {
            const _component_base_icon = _resolveComponent("base-icon");
            const _component_perfect_scrollbar = _resolveComponent("perfect-scrollbar");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["base-modal", [props.theme]])
            }, [
                _createVNode(_component_perfect_scrollbar, { class: "base-modal__scroll" }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                            _createElementVNode("div", _hoisted_2, [
                                _createElementVNode("div", _hoisted_3, [
                                    _createElementVNode("p", _hoisted_4, _toDisplayString(props.title), 1),
                                    _createVNode(_component_base_icon, {
                                        class: "base-modal__close",
                                        name: "close",
                                        onClick: _cache[0] || (_cache[0] = () => emits('close'))
                                    })
                                ]),
                                (_ctx.$slots.body)
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(props.scrollbar ? 'scrollbar' : 'div'), {
                                        key: 0,
                                        class: "base-modal__body"
                                    }, {
                                        default: _withCtx(() => [
                                            _renderSlot(_ctx.$slots, "body")
                                        ]),
                                        _: 3
                                    }))
                                    : _createCommentVNode("", true),
                                (_ctx.$slots.actions)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                        _renderSlot(_ctx.$slots, "actions")
                                    ]))
                                    : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", {
                                class: "base-modal__backdrop base-modal__backdrop-transparent",
                                onClick: _cache[1] || (_cache[1] = () => emits('close'))
                            })
                        ])
                    ]),
                    _: 3
                }),
                _createElementVNode("div", {
                    class: "base-modal__backdrop",
                    onClick: _cache[2] || (_cache[2] = () => emits('close'))
                })
            ], 2));
        };
    }
});
