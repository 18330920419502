import axios from "axios";
import { storeToRefs } from "pinia";
import { useScriptStore } from "@/store/script";
export default async function (step = null, elementId = null, value = null, action = null, section = null, sectionId = null, stepNum = null) {
    const { counter, history, meta } = storeToRefs(useScriptStore());
    if (!meta.value.localHistorySave)
        return;
    if (!counter.value)
        counter.value = 0;
    counter.value = counter.value + 1;
    if (!history.value)
        history.value = [{ messageCounter: 0 }];
    if (action) {
        const historyItem = {
            action,
            section,
            currentValue: value,
            step,
            stepNum,
            elementId,
            sectionId,
            counter: counter.value,
            time: Date.now(),
            timeChangedValue: 0
        };
        if (value) {
            historyItem.timeChangedValue = Math.floor(Date.now() / 1000);
        }
        history.value.push(historyItem);
    }
    history.value[0].messageCounter = history.value[0].messageCounter + 1;
    console.log(history.value);
    try {
        const response = await axios({
            method: 'POST',
            url: 'http://localhost:8888',
            data: history.value,
            headers: {
                "Content-type": "application/json"
            }
        });
    }
    catch (e) {
        console.log();
    }
}
