import axios from 'axios';
const BASE_API_URL = 'http://127.0.0.1:4059/';
export const switchto = (phone) => {
    return axios.get(`${BASE_API_URL}switchto`, { params: { number: phone } });
};
export const disconnectcall = () => {
    return axios.get(`${BASE_API_URL}disconnectcall`);
};
export const flashcall = () => {
    return axios.get(`${BASE_API_URL}flashcall`);
};
export const callto = (phone) => {
    return axios.get(`${BASE_API_URL}callto`, { params: { number: phone } });
};
