import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "base-accordeon__body"
};
import { reactive, toRefs, watch } from "vue";
import { THEME } from "@/components/@types";
const __default__ = { name: 'BaseAccordeon' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        theme: { default: THEME.dark }
    },
    emits: ['opened'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const data = reactive({
            isOpen: false
        });
        const { isOpen } = toRefs(data);
        const emits = __emit;
        watch(isOpen, next => {
            if (next)
                emits('opened');
        });
        return (_ctx, _cache) => {
            const _component_base_icon = _resolveComponent("base-icon");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["base-accordeon", [data.isOpen && 'active', props.theme]])
            }, [
                _createElementVNode("div", {
                    class: "base-accordeon__header",
                    onClick: _cache[0] || (_cache[0] = ($event) => (data.isOpen = !data.isOpen))
                }, [
                    _renderSlot(_ctx.$slots, "header"),
                    _createVNode(_component_base_icon, {
                        class: "base-accordeon__chevron",
                        name: "chevron",
                        width: "16",
                        height: "17"
                    })
                ]),
                (data.isOpen)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _renderSlot(_ctx.$slots, "body")
                    ]))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
