import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue";
import { BaseCheckbox } from "@/components/@base";
import { DIRECTION, THEME } from "@/components/@types";
import _ from "lodash";
const __default__ = { name: 'BaseCheckboxGroup' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        modelValue: { default: null },
        theme: { default: THEME.light },
        options: { default: () => [] },
        direction: { default: DIRECTION.horizontal }
    },
    emits: ["update:modelValue", "scrollTo"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emits = __emit;
        const update = (v) => {
            let value = _.cloneDeep(props.modelValue) || [];
            if (v.checked && !value?.includes(v.id))
                value.push(v.id);
            if (!v.checked && value?.includes(v.id))
                value = value.filter(i => i !== v.id);
            if (value.length === 0)
                value = null;
            emits('update:modelValue', value);
        };
        const scrollTo = (id) => {
            emits('scrollTo', id);
        };
        return (_ctx, _cache) => {
            return (props.options && props.options?.length > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(['base-checkbox-group', props.direction])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.options, (item) => {
                        return (_openBlock(), _createBlock(_unref(BaseCheckbox), {
                            class: "base-checkbox-group__item",
                            key: item.id,
                            checked: _ctx.modelValue?.includes(item.id),
                            id: item.id,
                            label: item.name,
                            anchor: item.anchor,
                            onUpdate: update,
                            onScrollTo: scrollTo
                        }, null, 8, ["checked", "id", "label", "anchor"]));
                    }), 128))
                ], 2))
                : _createCommentVNode("", true);
        };
    }
});
