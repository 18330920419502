import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "base-string-selection" };
import { INPUT_MODE } from "@/components/@base/@types";
import { BaseInput, BaseSelect } from "@/components/@base";
import InlineSvg from 'vue-inline-svg';
import { THEME } from "@/components/@types";
import _ from "lodash";
import { computed, reactive, toRef, watch } from "vue";
const __default__ = { name: 'BaseStringSelection' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        options: { default: () => [] },
        modelValue: { default: null },
        theme: { default: THEME.light },
        width: { default: '100%' },
        mode: { default: INPUT_MODE.nums },
        func: { type: [Function, null], default: null }
    },
    emits: ["update:modelValue"],
    setup(__props, { emit: __emit }) {
        _useCssVars(_ctx => ({
            "14f792f0": (props.width)
        }));
        const props = __props;
        const data = reactive({
            values: _.cloneDeep(props.modelValue) || [{ id: '', value: '' }]
        });
        const modelValue = toRef(props, 'modelValue');
        const lastItem = computed(() => {
            return data.values[data.values.length - 1];
        });
        const options = computed(() => {
            const opts = _.cloneDeep(props.options);
            if (!props.modelValue)
                return opts || [];
            const ids = props.modelValue.map(i => i.id);
            return opts?.map(i => {
                if (ids.includes(i.id))
                    i.disabled = true;
                return i;
            }) || [];
        });
        const emits = __emit;
        const add = () => {
            data.values.push({ id: '', value: '' });
        };
        const del = (id) => {
            if (data.values.length === 1 && data.values[0].id === '' && data.values[0].value === '')
                return;
            data.values = data.values.filter(i => i.id !== id);
            if (data.values.length === 0)
                data.values = [{ id: '', value: '' }];
            if (id !== '')
                emits('update:modelValue', data.values.length === 0 ? null : data.values);
        };
        const update = (v, id, type) => {
            if (type === 'select') {
                if (data.values.find(i => i.id === id))
                    data.values.find(i => i.id === id).id = v;
                if (!data.values.find(i => i.id === v))
                    data.values.push({ id: v, value: '' });
            }
            if (type === 'input') {
                if (data.values.find(i => i.id === id))
                    data.values.find(i => i.id === id).value = v;
                if (!data.values.find(i => i.id === id))
                    data.values.push({ id: '', value: v });
            }
            if (v)
                emits('update:modelValue', data.values);
        };
        watch(modelValue, next => {
            data.values = _.cloneDeep(next) || [{ id: '', value: '' }];
        }, { deep: true });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.values, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                        class: "base-string-selection__row",
                        key: item.id
                    }, [
                        _createVNode(_unref(BaseSelect), {
                            class: "base-string-selection__select",
                            modelValue: item.id,
                            width: "55%",
                            theme: props.theme,
                            options: options.value,
                            "onUpdate:modelValue": ($event) => (update($event, item.id, 'select'))
                        }, null, 8, ["modelValue", "theme", "options", "onUpdate:modelValue"]),
                        _createVNode(_unref(BaseInput), {
                            class: "base-string-selection__input",
                            modelValue: item.value,
                            theme: props.theme,
                            mode: props.mode,
                            width: "25%",
                            "onUpdate:modelValue": ($event) => (update($event, item.id, 'input'))
                        }, null, 8, ["modelValue", "theme", "mode", "onUpdate:modelValue"]),
                        _createVNode(_unref(InlineSvg), {
                            class: "base-string-selection__delete",
                            src: "/svg/delete.svg",
                            onClick: ($event) => (del(item.id))
                        }, null, 8, ["onClick"])
                    ]));
                }), 128)),
                (options.value.filter(i => !i.disabled).length > 0 && (lastItem.value.id !== '' && lastItem.value.value !== ''))
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "base-string-selection__add",
                        onClick: add
                    }, " Добавить "))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
